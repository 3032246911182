<template>
  <div class="content">
    <img class="img-style margin-global" src="./../assets/error.svg" />
    <div class="text-content margin-global">
      <p class="title is-1">404</p>
      <p class="text-error">Oops! Lo sentimos, algo salió mal.</p>
      <p class="text-error">No podemos encontrar la página que estas buscando.</p>
      <button class="button-blue" @click="returnPrevView">
        Regresar a la página anterior
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions(['returnPrevView'])
  }
}
</script>
